import axios from "axios"
import moment from "moment"
import { RecentActivities } from "../../components/dashboard/Home"
import {
  ScheduleState,
  ScheduleStateError,
} from "../../components/dashboard/LogisticEditor"
import {
  PreferredTime,
  PreferrredDay,
  Process,
  ProcessType,
  ScheduleModel,
  Status,
} from "../../models/Schedule"
import { TransactionModel } from "../../models/Transaction"
import { UserModel } from "../../models/User"
import { AppointmentState } from "../../pages/createAppointment"
import { utilCurrency, utilPhoneRegex } from "../../utils/region"
import { Language } from "../reducers/utilsReducer"
import { localeContent } from "../../utils/locale"
import { CustomRQ, CustomWQ } from "../../utils/clientQuery"

export const schedulesDataLimit: number = 10

export const getScheduleWithPagination = (
  paginationStartAfter: string | Date,
  firebase: any
) => {
  return async (dispatch: any, getState: any) => {
    try {
      const schedulesCollectionQuery = firebase
        .firestore()
        .collection("schedules")
        .where("userId", "==", firebase.auth().currentUser.uid)
        .orderBy("updatedAt", "desc")
        .limit(schedulesDataLimit)

      const schedulesList: ScheduleModel[] = getState().scheduleStore.schedules
      const newScheduleList: ScheduleModel[] = []

      updateScheduleLoadingState(dispatch, true)

      let schedulesSnapshot
      if (paginationStartAfter) {
        schedulesSnapshot = await schedulesCollectionQuery
          .startAfter(paginationStartAfter)
          .get()
      } else {
        schedulesSnapshot = await schedulesCollectionQuery.get()
      }
      if (schedulesSnapshot) {
        schedulesSnapshot.forEach((eachDoc: any) => {
          const eachSchedule = eachDoc.data() as ScheduleModel
          newScheduleList.push(eachSchedule)
        })

        let lastCursor: Date | string = ""

        if (newScheduleList.length > 0) {
          if (newScheduleList.length === schedulesDataLimit) {
            lastCursor = newScheduleList[schedulesDataLimit - 1].updatedAt
          }

          dispatch({
            type: "UPDATE_SCHEDULE",
            payload: {
              schedules: schedulesList.concat(newScheduleList),
              lastCursor: lastCursor,
            },
          })
        } else {
          dispatch({
            type: "UPDATE_SCHEDULE",
            payload: {
              schedules: schedulesList,
              lastCursor: lastCursor,
            },
          })
        }
      }
      updateScheduleLoadingState(dispatch, false)
    } catch (err) {
      updateScheduleLoadingState(dispatch, false)
      return err.message
    }
  }
}

export const clearSchedule = () => {
  return (dispatch: any) => {
    dispatch({
      type: "UPDATE_SCHEDULE",
      payload: {
        schedules: [],
        lastCursor: "",
      },
    })
  }
}

export const updateSchedulePageIndex = (pageIndex: number) => {
  return (dispatch: any) => {
    dispatch({
      type: "UPDATE_SCHEDULE_PAGE_INDEX",
      payload: {
        pageIndex: pageIndex,
      },
    })
  }
}

const updateScheduleLoadingState = (dispatch: any, loading: boolean) => {
  dispatch({
    type: "UPDATE_SCHEDULES_LOADING",
    payload: {
      loading: loading,
    },
  })
}

export const checkScheduleAvailability = async (firebase: any) => {
  let availability = true
  if (firebase.auth().currentUser?.uid) {
    const scheduleLimit = await firebase
      .firestore()
      .collection("schedules")
      .where("userId", "==", firebase.auth().currentUser.uid)
      .limit(1)
      .get()

    if (!scheduleLimit.empty) {
      availability = false
    }
  }
  return availability
}

export const handleScheduleCondition = (
  scheduleAttributeState: ScheduleState,
  scheduleAttributeError: ScheduleStateError,
  typeList: string[],
  language?: Language
) => {
  const selectedLanguage = language ?? Language.EN
  typeList.map(eachType => {
    switch (eachType) {
      case "phone":
        const phoneFilter = utilPhoneRegex()
        if (!phoneFilter.test(scheduleAttributeState.phone)) {
          scheduleAttributeError["phoneError"] = localeContent(
            selectedLanguage
          ).scheduleCondition.phoneError
        } else {
          scheduleAttributeError["phoneError"] = ""
        }
        break
      case "name":
        if (scheduleAttributeState.name?.length === 0) {
          scheduleAttributeError["nameError"] = localeContent(
            selectedLanguage
          ).scheduleCondition.nameError
        } else {
          scheduleAttributeError["nameError"] = ""
        }
        break
      case "address":
        if (
          scheduleAttributeState.address.name.replace(/\s/g, "").length <= 0
        ) {
          scheduleAttributeError["addressError"] = localeContent(
            selectedLanguage
          ).scheduleCondition.addressError
        } else {
          scheduleAttributeError["addressError"] = ""
        }
        break
      case "preferredTime":
        if (!scheduleAttributeState.preferredTime) {
          scheduleAttributeError["preferredTimeError"] = localeContent(
            selectedLanguage
          ).scheduleCondition.preferredTimeError
        } else {
          scheduleAttributeError["preferredTimeError"] = ""
        }
        break
      case "preferredDay":
        if (!scheduleAttributeState.preferredDay) {
          scheduleAttributeError["preferredDayError"] = localeContent(
            selectedLanguage
          ).scheduleCondition.preferredDayError
        } else {
          scheduleAttributeError["preferredDayError"] = ""
        }
        break
      case "estimatedWeight":
        if (Number(scheduleAttributeState.estimatedWeight) >= 5) {
          scheduleAttributeError["estimatedWeightError"] = ""
        } else {
          scheduleAttributeError["estimatedWeightError"] = localeContent(
            selectedLanguage
          ).scheduleCondition.estimatedWeightError
        }
        break
      case "image":
        if (scheduleAttributeState.image) {
          const fileExtensionFilter = /.(jpg|jpeg|png)$/
          const fileSize = scheduleAttributeState.image.size / 1024 / 1024
          if (!fileExtensionFilter.test(scheduleAttributeState.image.name)) {
            scheduleAttributeError["imageError"] = localeContent(
              selectedLanguage
            ).scheduleCondition.noImageError
          } else if (fileSize > 5) {
            scheduleAttributeError["imageError"] = localeContent(
              selectedLanguage
            ).scheduleCondition.ImageTooLargeError
          } else {
            scheduleAttributeError["imageError"] = ""
          }
        }
        break
    }
    return null
  })
}

export const createSchedule = async (
  scheduleState: ScheduleState,
  user: UserModel | null,
  firebase: any,
  userId?: string
) => {
  try {
    let userUUID = ""
    if (user && user.id) {
      userUUID = user.id
    } else if (userId) {
      userUUID = userId
    } else {
      userUUID = firebase.auth().currentUser.uid
    }
    const scheduleRef = firebase.firestore().collection("schedules").doc()
    const newScheduleRef = scheduleRef.id
    const uploadTask = await firebase
      .storage()
      .ref("schedules")
      .child(newScheduleRef + `/preview.jpg`)
      .put(scheduleState.image)
    const uploadTaskUrl: string = await uploadTask.ref.getDownloadURL()
    const urlParams = new URLSearchParams(uploadTaskUrl)
    const imageToken = urlParams.get("token")

    const newProcess: Process = {
      type: "CREATED" as keyof typeof ProcessType,
      date: new Date(),
    }
    if (imageToken) {
      const scheduleModel: ScheduleModel = {
        id: newScheduleRef,
        userId: userUUID,
        phone: scheduleState.phone,
        address: scheduleState.address,
        image: imageToken,
        updatedAt: new Date(),
        status: "PEND" as keyof typeof Status,
        preferredDay: scheduleState.preferredDay as keyof typeof PreferrredDay,
        preferredTime: scheduleState.preferredTime as keyof typeof PreferredTime,
        additionRemark: scheduleState.additionRemark,
        estimatedWeight: scheduleState.estimatedWeight,
        processes: [newProcess],
      }
      await scheduleRef.set(scheduleModel)

      const title = `[Arus Oil] Appointment for Collection of UCO - ${moment().format(
        "DD/MM/YY"
      )}`
      const content = `Hi ${user?.name},<br><br>You have scheduled an appointment for the collection of your used cooking oil (UCO) at ${scheduleModel.address.name}.<br><br> Our team will contact you for exact date of collection within 1-2 working days.<br><br>Thanks,<br>Arus Oil`
      emailNotificationForSchedule(user, title, content)
      return ""
    } else {
      return "Unknown error, please contact developer at info@arusoil.com if this continues"
    }
  } catch (err) {
    return err.message
  }
}

export const checkAppointmentUserExist = async (
  appointmentState: AppointmentState
) => {
  try {
    const isProd = process.env.GATSBY_FIREBASE_ENV === "production"
    const appointmentDetailsAPILoc = isProd ? "arusoil-web" : "arusoil-web-dev"
    const appointmentDetailsAPI = `https://asia-southeast2-${appointmentDetailsAPILoc}.cloudfunctions.net/checkAppointmentCreation`

    const appointmentDetailRes = await axios.post(appointmentDetailsAPI, {
      name: appointmentState.name,
      phoneNumber: appointmentState.phone,
    })

    return appointmentDetailRes.data.uid
  } catch (err) {
    return ""
  }
}

export const handleCancelSchedule = async (
  selectedId: string,
  user: UserModel | null,
  newProcess: Process[],
  firebase: any
) => {
  try {
    if (firebase.auth().currentUser?.uid) {
      newProcess.push({
        type: "CANCELLED" as keyof typeof ProcessType,
        date: moment().toDate(),
      })

      const scheduleRef = firebase
        .firestore()
        .collection("schedules")
        .doc(selectedId)

      await scheduleRef.update({
        processes: newProcess,
        status: "CANCEL" as keyof typeof Status,
        updatedAt: moment().toDate(),
      })

      const scheduleQuery = await scheduleRef.get()
      if (scheduleQuery.exists) {
        const selectedScheduleData = scheduleQuery.data() as ScheduleModel
        const title = `[Arus Oil] Appointment Cancellation for Collection of UCO - ${moment().format(
          "DD/MM/YY"
        )}`
        const content = `Hi ${user?.name},<br><br>You have cancelled the appointment for UCO collection at ${selectedScheduleData.address.name}.<br><br>If you do face any problems,feel free to contact us at info@arusoil.com <br><br>Thanks,<br>Arus Oil`
        emailNotificationForSchedule(user, title, content)
      }

      return ""
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues"
  }
}

export const handleCancelScheduleAPI = async (
  selectedId: string,
  firebase: any
) => {
  try {
    const scheduleRQ = new CustomRQ("schedules", firebase)
    const scheduleResp = await scheduleRQ.call({
      type: "get",
      id: selectedId,
    })
    if (scheduleResp?.data) {
      const newSchedule = scheduleResp.data as ScheduleModel
      const currentProcess = newSchedule.processes
      currentProcess.push({
        type: "CANCELLED" as keyof typeof ProcessType,
        date: moment().toDate(),
      })
      const scheduleWQ = new CustomWQ("schedules", firebase)
      await scheduleWQ.call("update", {
        id: selectedId,
        data: {
          status: "CANCEL",
          processes: currentProcess,
        },
      })
      return true
    }
  } catch (err) {
    return false
  }
}

export const getSchedule = async (selectedId: string, firebase: any) => {
  try {
    if (firebase.auth().currentUser?.uid && selectedId) {
      let schedule = await firebase
        .firestore()
        .collection("schedules")
        .doc(selectedId)
        .get()

      return schedule.data()
    } else {
      return "Unknown error, please contact developer at info@arusoil.com if this continues"
    }
  } catch (err) {
    return "Unknown error, please contact developer at info@arusoil.com if this continues"
  }
}

export const getRecentActivities = async (firebase: any) => {
  try {
    if (firebase.auth().currentUser?.uid) {
      const schedulesSnapshot = await firebase
        .firestore()
        .collection("schedules")
        .where("userId", "==", firebase.auth().currentUser.uid)
        .orderBy("updatedAt", "desc")
        .limit(3)
        .get()

      const activitiesList: RecentActivities[] = []
      if (schedulesSnapshot) {
        schedulesSnapshot.forEach((eachDoc: any) => {
          const eachSchedule = eachDoc.data() as ScheduleModel

          let scheduleMessage = ""
          switch (eachSchedule.status) {
            case "CANCEL":
            case "DEC":
            case "PLAN":
              scheduleMessage = `Your appointment for UCO collection at ${
                eachSchedule.address.name
              } has been ${Status[eachSchedule.status].toLowerCase()}`
              break
            case "COM":
              const lastMessage =
                eachSchedule.processes[eachSchedule.processes.length - 1]
              scheduleMessage = `Your ${
                lastMessage.description
              }kg UCO have been collected at ${
                eachSchedule.address.name
              } which costs ${utilCurrency()} ${lastMessage.amount}`
              break
            case "DISP":
              scheduleMessage = `A collection vehicle has been dispatched for your UCO collection at ${eachSchedule.address.name}`
              break
            case "PEND":
              scheduleMessage = `You have scheduled for an appointment for UCO collection at ${eachSchedule.address.name}`
              break
            default:
              break
          }

          activitiesList.push({
            activity: scheduleMessage,
            amount: " - ",
            date: moment(eachSchedule.updatedAt.seconds * 1000).format(
              "DD/MM/YY"
            ),
            dateObject: moment(eachSchedule.updatedAt.seconds * 1000),
            status: Status[eachSchedule.status],
          })
        })
      }

      const transactionsSnapshot = await firebase
        .firestore()
        .collection("transactions")
        .where("userId", "==", firebase.auth().currentUser.uid)
        .orderBy("date", "desc")
        .limit(3)
        .get()

      if (transactionsSnapshot) {
        transactionsSnapshot.forEach((eachDoc: any) => {
          const eachTransaction = eachDoc.data() as TransactionModel
          activitiesList.push({
            activity: `A total of ${utilCurrency()} ${
              eachTransaction.amount
            } has been ${
              eachTransaction.type === "CREDIT"
                ? "withdrawed from"
                : "deposited to"
            } your account`,
            amount: `${utilCurrency()} ${eachTransaction.amount}`,
            date: moment(eachTransaction.date.seconds * 1000).format(
              "DD/MM/YY"
            ),
            dateObject: moment(eachTransaction.date.seconds * 1000),
            status: eachTransaction.type,
          })
        })
      }

      activitiesList.sort(function (a, b) {
        return a.dateObject.isAfter(b.dateObject) ? -1 : 0
      })

      return activitiesList
    } else {
      return ""
    }
  } catch (err) {
    return ""
  }
}

export const emailNotificationForSchedule = async (
  user: UserModel | null,
  title: string,
  content: string
) => {
  try {
    if (user?.email) {
      await axios.post(
        "https://api.enginemailer.com/RESTAPI/Submission/SendEmail",
        {
          UserKey: process.env.GATSBY_EMAIL_SEND_API,
          ToEmail: user?.email,
          SenderEmail: "noreply@arusoil.com",
          SenderName: "Arus Oil",
          Subject: title,
          SubmittedContent: content,
        }
      )
    }
  } catch (err) {}
}
